/*eslint-disable*/
import React, {useEffect} from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Index() {
  useEffect(() => {
    document.documentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, []);

  return (
    <>
      <IndexNavbar className="" />
      <section className="header relative pt-16 items-center flex h-screen max-h-860-px">
        <div className="container mx-auto items-center flex flex-wrap">
          <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
            <div className="pt-32 sm:pt-0">
              <h2 className="font-semibold text-4xl text-blueGray-600">
                Network365 - Offering IT and Network support to all sizes of
                businesses.
              </h2>
              <div className="mt-12">
                      <Link
                        className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                        to="/about-us#contact-section"
                      >
                        Get in touch
                      </Link>
              </div>
            </div>
          </div>
        </div>

        <img
          className="absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860px"
          src={require("assets/img/pattern_react.png")}
          alt="..."
        />
      </section>

      <section className="mt-48 md:mt-40 pb-40 relative">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-100 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div className="container mx-auto px-4 pb-32 pt-48">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-5/12 ml-auto px-12 md:px-4">
              <div className="md:pr-12">
                <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                  <i className="fas fa-file-alt text-xl"></i>
                </div>
                <h3 className="text-3xl font-semibold">About Us</h3>
                <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                  At Network365, we understand the pivotal role that a robust
                  and reliable network infrastructure plays in the success of
                  modern enterprises. With over 20 years of experience in the
                  industry, we have been at the forefront of providing top-notch
                  network support services to businesses of all sizes. Our
                  mission is to empower our clients with seamless connectivity
                  solutions, allowing them to focus on their core objectives
                  while we handle the intricacies of their network
                  infrastructure. Driven by a passion for technology and a
                  commitment to excellence, our team of highly skilled
                  professionals leverages cutting-edge tools and methodologies
                  to design, implement, and maintain networks that are
                  efficient, secure, and scalable. We pride ourselves on our
                  ability to deliver personalized solutions tailored to meet the
                  unique needs and challenges of each client, ensuring optimal
                  performance and maximum uptime. At Network365, we believe in
                  building long-lasting partnerships with our clients, based on
                  trust, transparency, and reliability. Whether you are looking
                  to optimize your existing network, implement a new
                  infrastructure, or need ongoing support and maintenance, we
                  are here to help you navigate the complexities of modern
                  networking with confidence and peace of mind.
                </p>
              </div>
            </div>

            <div className="w-full md:w-6/12 mr-auto px-4 pt-24 md:pt-0">
              <img
                alt="..."
                className="max-w-full rounded-lg shadow-xl"
                style={{
                  transform:
                    "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                }}
                src={require("assets/img/switch.jpg")}
              />
            </div>
          </div>
        </div>

        <div className="justify-center text-center flex flex-wrap mt-24">
          <div className="w-full md:w-6/12 px-12 md:px-4">
            <h2 className="font-semibold text-4xl">Our Clients</h2>
          </div>
        </div>
      </section>

      <section className="block relative z-1 bg-blueGray-600">
        <div className="container mx-auto">
          <div className="justify-center flex flex-wrap">
            <div className="w-full lg:w-12/12 px-4  -mt-24">
              <div className="flex flex-wrap">
                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="text-xl font-semibold pb-4 text-center">
                    HSBC
                  </h5>
                  <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words justify-center items-center h-64 bg-blueGray-600 w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                    <img
                      alt="HSBC Logo"
                      className="align-middle border-none max-w-full h-auto rounded-lg"
                      src={require("assets/img/hsbc-logo-london.jpg")}
                    />
                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="text-xl font-semibold pb-4 text-center">
                    Vodafone
                  </h5>
                  <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words justify-center items-center h-64 bg-blueGray-600 w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                    <img
                      alt="Vodafone Logo"
                      className="align-middle border-none max-w-full h-auto rounded-lg"
                      src={require("assets/img/Vodafone-Logo.png")}
                    />
                  </div>
                </div>

                <div className="w-full lg:w-4/12 px-4">
                  <h5 className="text-xl font-semibold pb-4 text-center">
                    Barclays
                  </h5>
                  <div className="p-8 hover:-mt-4 relative flex flex-col min-w-0 break-words justify-center items-center h-64 bg-blueGray-600 w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                    <img
                      alt="Barclays Logo"
                      className="align-middle border-none max-w-full h-auto rounded-lg"
                      src={require("assets/img/Barclays.png")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-20 bg-blueGray-600 overflow-hidden">
        <div className="container mx-auto pb-64">
          <div className="flex flex-wrap justify-center">
            <div className="w-full px-12 md:px-4 ml-auto mr-auto md:mt-64">
              <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                <i class="fas fa-network-wired text-xl"></i>
              </div>
              <h3 className="text-3xl mb-2 font-semibold leading-normal text-white">
                Services
              </h3>
              <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-200">
                <ul>
                  <li>
                    <strong>Network Design and Implementation</strong>
                    <br />
                    Our expert engineers work closely with you to design and
                    deploy customized network solutions tailored to your
                    specific requirements. From initial planning to final
                    implementation, we ensure a seamless and efficient process
                    that minimizes downtime and maximizes performance.
                  </li>
                  <li>
                    <strong>Network Security Solutions</strong>
                    <br />
                    Protect your valuable data and assets with our comprehensive
                    network security services. We employ the latest security
                    measures and protocols to safeguard your network against
                    cyber threats, unauthorized access, and data breaches,
                    giving you peace of mind knowing that your business is
                    protected.
                  </li>
                  <li>
                    <strong>Network Monitoring and Maintenance</strong>
                    <br />
                    Stay ahead of potential issues and ensure optimal
                    performance with our proactive network monitoring and
                    maintenance services. Our team monitors your network 24/7,
                    identifying and resolving issues before they escalate, and
                    implementing regular maintenance tasks to keep your network
                    running smoothly.
                  </li>
                  <li>
                    <strong>Wireless Networking Solutions</strong>
                    <br />
                    Enable seamless connectivity across your organization with
                    our wireless networking solutions. Whether you need to
                    deploy a Wi-Fi network for your office space or extend your
                    network coverage to remote locations, we have the expertise
                    to design and implement reliable wireless solutions tailored
                    to your needs.
                  </li>
                  <li>
                    <strong>Cloud Networking Services</strong>
                    <br />
                    Harness the power of the cloud with our cloud networking
                    services. We help you leverage cloud technologies to
                    optimize your network infrastructure, improve scalability,
                    and enhance collaboration and productivity across your
                    organization.
                  </li>
                  <li>
                    <strong>Consulting and Advisory Services</strong>
                    <br />
                    Benefit from our extensive industry knowledge and expertise
                    with our consulting and advisory services. Whether you need
                    guidance on network strategy, technology selection, or
                    compliance requirements, our experienced consultants are
                    here to provide tailored solutions and strategic insights to
                    help you achieve your business goals.
                  </li>
                </ul>
              </p>
              <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-400">
                At Network365, we are committed to delivering exceptional
                network support services that empower your business to thrive in
                today's digital landscape. Contact us today to learn more about
                our services and how we can help you achieve your networking
                goals.{" "}
              </p>
            </div>

            {/* <div className="w-full md:w-4/12 px-4 mr-auto ml-auto mt-32 relative">
              <i className="fab fa-github text-blueGray-700 absolute -top-150-px -right-100 left-auto opacity-80 text-55"></i>
            </div> */}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
